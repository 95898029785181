@import "../../../../../styles/helpers";

.row {
    display: table-row;
    transition: all .2s;
    &:hover {
        background: $n2;
        @include dark {
            background: $n5; } } }


.col {
    position: relative;
    display: table-cell;
    vertical-align: center; }

.col1 {
    display: flex;
    flex-direction: row; }

.email {
    height: 30px;
    width: 90%;
    margin-top: 5px;
    display: flex;
    justify-content: end;
    padding: 5px;
    border-radius: 8px;
    background-color: #efefef;
    font-size: 13px;
    @include t {
        font-size: 12px; }
    @include m {
        font-size: 11px; }
    @include dark {
        color: white;
        background-color: #272b30; } }


.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s; }

.avatar {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.avatar2 {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    @include t {
        margin-right: 0px;
        img {
            width: 60%;
            height: 60%;
            object-fit: cover; } } }

.user {
    max-width: 160px;
    cursor: pointer; }

