@import "../../../styles/helpers";

.search {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    @include t {
        width: 100%; }
    &.active {
        .result {
            visibility: hidden;
            opacity: 0; }
        .close {
            visibility: visible;
            opacity: 1; }
        .bodyOut {
            visibility: visible;
            opacity: 1; } } }

.head {
    position: relative;
    z-index: 2; }

.start,
.result,
.close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    svg {
        transition: fill .2s; } }

.start {
    left: 12px;
    svg {
        fill: $n4; }
    &:hover {
        svg {
            fill: $n7; } } }

.close {
    right: 10px;
    width: 32px;
    height: 32px;
    svg {
        fill: $shades1; }
    &:hover {
        svg {
            fill: $p3; } } }
.start {
    visibility: visible;
    opacity: 1; }
.direction,
.close {
    visibility: visible;
    opacity: 1; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 68px 0 42px;
    background: $n2;
    border: 2px solid transparent;
    border-radius: 12px;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        background: $n6;
        color: $n1; }
    &::placeholder {
        color: $shades1; }
    &:focus {
        border-color:  rgba(241, 182, 0, 1);
        background: $n;
        @include dark {
            background: $n8; } } }

.bodyOut {
    z-index: 999;
    position: absolute;
    max-height: 300px;
    width: 100%;
    box-shadow: 0 0 0 2px rgba(241, 182, 0, 1);
    padding: 10px;
    margin-top: 8px;
    border: 6px solid transparent;
    background: $n;
    border-radius: 16px;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    @include dark {
        background: $n7;
        border-color: #f0b500;
        box-shadow: 0 0 0 2px $n6; } }

.itm {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 10px; }

.avatar {
    display: flex;
    items-align: center;
    flex-shrink: 0;
    width: 42px;
    height: 42px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

