@import "../../../../styles/helpers";

.item {
    display: flex;
    padding: 16px;
    flex: 0 0 50%;
    border-radius: 12px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: all .2s;
    @include w {
        padding: 16px 12px; }
    @include m {
        display: block; } }

.img {
    width: 24px;
    height: 24px; }


.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 50%;
    @include m {
        display: none; } }

.subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include caption1;
    color: $n4; }

.tooltip {
    svg {
        fill: $shades1; } }

.counter {
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.03em;
    @include w {
        font-size: 22px;
        line-height: 30px; } }
