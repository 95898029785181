@import "../../../styles/helpers";

.wrapper {
    margin: 0 -12px;
    @include t {
        margin: 0; } }

.table {
    display: table;
    width: 100%; }

.row {
    display: table-row;
    width: 100%; }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include caption1;
    color: $n4; }


.foot {
    margin-top: 20px;
    text-align: center; }
