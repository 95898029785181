@import "../../styles/helpers";
.searchHead {
    width: 100%; }

.count {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 20px;
    @include m {
        font-size: 12px; } }

.headModal {
    display: flex;
    justify-content: space-between; }

.upperContentLeft {
    margin-right: 20px; }

.upperContent2 {
    height: 70px;
    display: flex;
    align-items: center; }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    margin: 10px;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButton {
    margin-right: 20px; }

.icon {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $BB_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 6px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: $BB_prime_hover; } }

.iconDis {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7a8086;
    fill: white;
    border-radius: 4px;
    padding: 6px;
    color: white;
    gap: 4px;
    cursor: default; }

.addNew {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start; }


.icon {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center; }

.icons {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 4px; }

.iconsis {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }

.iconY {
    font-size: 18px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BB_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    &:hover {
        background-color: $BB_prime_hover; } }

.iconR {
    font-size: 18px;
    height: 25px;
    width: 25px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: $red;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    &:hover {
        background-color: $red_hover; } }

.nadpis {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    height: 30px; }

.polozka_doklad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
    @include dark {
        color: white; } }

.icon_red {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    color: $red;
    display: flex;
    justify-content: center;
    align-items: center; }
