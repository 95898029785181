@import "../../../styles/helpers";

.searchHead {
    width: 100%; }

.spacebeetween {
    display: flex;
    height: 30px; }

.buttonPotvrdeniePrevod {
    display: flex;
    align-items: center; }

.title {
    display: flex;
    align-items: center;
    font-size: 18px;
    @include t {
        font-size: 15px; } }


.header {
    display: flex;
    justify-content: space-between; }

.count {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    font-size: 12px; }

.headModal {
    display: flex;
    justify-content: space-between; }

.textarea2_title {
    width: 100%; }

.textarea2 {
    height: 80px;
    width: 100%;
    border-radius: 4px;
    background-color: #f4f4f4;
    @include dark {
        background-color: #272b30;
        color: white; }
    @include m {
       height: 40px;
       width: 100%; } }


.buttonZmena {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110%;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 2px;
    &:hover {
        background-color: rgba(190,144,0,255); }
    @include t {
        font-size: 12px; } }

.upperContent {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    font-size: 12px; }

.upperContent2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 12px; }

.upperContentInside {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: end; }

.text_inputik {
    display: flex; }

.inputik {
    color: #000;
    font-weight: 700;
    padding: 4px;
    background-color: #EFEFEF;
    border-radius: 2px;
    width: 100px;
    font-size: 13px;
    @include m {
        width: 85px; } }

.text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px; }

.Content {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row; }

.ContentLeft {
    height: 100px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column; }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    fill: $BB_prime;
    @include m {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }


.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.cieloveStredisko {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }

.cieloveStrediskoButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; }

.cieloveStrediskoContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 5px; }

.drop_zone_aroud {
    height: 100px;
    width: 350px;
    @include m {
        width: 150px; } }
