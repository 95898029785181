@import "../../../styles/helpers";


.ico_Title {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }


.outer {
    max-width: 600px;
    @include hd {
        widhth: 100%; } }

.searcheable {
    height: 100%;
    display: flex;
    flex-direction: column; }


.content {
    height: 95%;
    overflow-y: auto; }

.nacitaj {
    height: 5%;
    display: flex;
    flex-direction: row;
    align-items: end; }

.text {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center; }

.textB {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $BB_prime;
    &:hover {
       cursor: pointer;
       color: $BB_prime_hover; } }


.pocet {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: end; }

.row {
    cursor: pointer;
    border-radius: 4px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        background-color: #f4f4f4;
        @include dark {
            background-color: #262b30; } } }

.avatar {
    background-color: green;
    border-radius: 50%;
    height: 35px;
    width: 35px; }

.avatarArea {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center; }

.upperText {
    font-size: 15px;
    font-weight: 400px; }

.downText {
    display: flex;
    gap: 8px;
    font-size: 13px;
    font-weight: 400px;
    color: #9E9E9E; }
.left {
    width: 100%;
    display: flex;
    align-items: center; }

.right {
    margin-right: 5px; }
