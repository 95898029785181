@import "./../../styles/helpers";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #23a638;
    color: white;
    height: 100px;
    margin: 20px;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 10px 10px 30px -8px rgba(0, 0, 0, 0.3), 8px 32px 48px -8px rgba(0, 0, 0, 0.15);
    &:hover {
        cursor: pointer;
        background-color: #19d437; } }

.overlay {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.title {
    width: 50%;
    height: 100px;
    display: flex;
    justify-content: start;
    align-items: center; }

.x_ {
    width: 50%;
    height: 100px;
    display: flex;
    justify-content: end;
    align-items: center; }

.x {
    cursor: pointer; }


.title_x {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
