@import "../../../../styles/helpers";

.positive {
    display: inline-flex;
    align-items: center;
    margin-right: 2px;
    @include caption2;
    height: 24px;
    padding: 0 4px;
    border-radius: 8px;
    svg {
        margin-right: 4px; } }


.icon {
    width: 16px;
    height: 16px;
    margin-left: 5px; }

.box {
    display: flex;
    flex-direction: row;
    padding: 8px; }

.box1 {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #f9eabd;
    border-radius: 8px;
    color: $BB_prime;
    svg {
        fill: $BB_prime; } }

.box2 {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #f9eabd;
    border-radius: 8px;
    color: $BB_prime;
    svg {
        fill: $BB_prime; } }

.box3 {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 8px;
    background-color: #efefef;
    color: #9a9fa5;
    svg {
        fill: #9a9fa5; } }
