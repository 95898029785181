@import "../../../../styles/helpers";

.wrapper1 {}

.wrapper {
    margin: 0 0px; }

.spacebeetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px; }

.avatar {
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.avatar2 {
    img {
        width: 24px;
        height: 24px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px; }

.nazov {
    display: flex;
    flex-direction: row;
    padding: 4px;
    font-size: 10px; }

.outer {
    max-width: 1200px; }

.buttonPS {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px; }

.buttonPSI {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    fill: white;
    background-color: #f0b500;
    border-radius: 50%;
    padding: 2px; }


.buttonPST {
    display: flex;
    justify-content: center;
    items-align: center;
    color: #f0b500;
    font-size: 12px;
    height: 100%; }

.count {
    display: flex;
    justify-content: center;
    items-align: center;
    font-size: 12px;
    height: 100%; }

.icon1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $BB_prime;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: rgba(190,144,0,255); } }

.icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $red;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: $red_hover; } }


.icons {
    display: flex;
    align-items: center; }

.vyd_kom {
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between; }


.kom {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center; }

.textarea {
    width: 100%;
    height: 100px;
    resize: false;
    border-radius: 4px;
    background-color: #f4f4f4;
    @include dark {
        background-color: #272b30;
        color: white; } }

.cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
