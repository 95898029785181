@import "../../../../styles/helpers";

.row {
    display: table-row;
    transition: all .2s; }

.col {
    position: relative;
    display: table-cell;
    vertical-align: top;
    padding: 16px 12px;
    color: $n7; }

.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s;
    &:hover {
        color: $p1; } }

.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.user {
    max-width: 160px;
    cursor: pointer; }

.login {
    @include caption1;
    color: $n4;
    @include dark {
        color: $shades1; } }

.email {
    @include base2;
    color: $n4; }


.lifetime {
    display: inline-flex;
    align-items: center;
    @include m {
        flex-direction: column;
        align-items: flex-end; } }

.price {
    display: inline-block;
    padding: 0 8px;
    border-radius: 6px;
    background: $n3;
    transition: backround .2s;
    @include dark {
        background: $n6; } }

.balance {
    margin-left: 8px;
    @include m {
        margin: 4px 0 0; } }

