@import "../../styles/helpers";

.textarea {
    width: 100%;
    height: 90px;
    resize: false;
    border-radius: 4px;
    background-color: #f4f4f4;
    @include dark {
        background-color: #272b30;
        color: white; } }
.area {
    display: flex;
    justify-content: center;
    item-align: center;
    flex-direction: column; }

.searchHead {
    width: 100%; }

.headModal {
    display: flex;
    justify-content: space-between; }

.headModalButtonsActive {
    color: $BB_prime;
    font-weight: bold;
    border-bottom: 1.5px solid $BB_prime;
    margin: 7px;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    margin: 10px;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButton {
    margin-right: 20px; }

.dropzone {
    background-color: #f4f4f4;
    @include dark {
         background-color: #262b30;
         color: white; } }

