@import "../../../../styles/helpers";

.avatar {
    flex-shrink: 0;
    margin: 2px;
    display-flex {}
    align-items: center;
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px; }

.card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.nazov {
    display: flex;
    flex-direction: row;
    padding: 4px; }

.toolbar {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 10px; }


.buttonZmena {
    height: 30px;
    width: 30px;
    font-weight: bold;
    padding: 3px;
    background-color: transparent;
    border-radius: 4px;
    margin-right: 5px;
    fill: #a7acaf;
    &:hover {
        background-color: #f5f7fb;
        @include dark {
            background-color: #242d34; } }
    @include t {
        font-size: 12px; } }

.search {
    display: flex;
    align-items: center; }

.searchInput {
    border-bottom: 1px solid #a7acaf;
    background-color: transparent;
    color: #666d74;
    font-size: 15px;
    &:focus {
        border-bottom: 1px solid $BB_prime; } }

.GridToolbarContainer {
    width: 100%;
    display: flex;
    justify-content: end; }

