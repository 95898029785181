@import "../../../styles/helpers";

.wrapper1 {}

.wrapper {
    margin: 0 0px; }

.spacebeetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px; }

.avatar {
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.avatar2 {
    img {
        width: 24px;
        height: 24px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px; }

.nazov {
    display: flex;
    flex-direction: row;
    padding: 4px;
    font-size: 10px; }

.outer {
    max-width: 1200px; }

.buttonPS {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 15px;
    cursor: pointer; }

.buttonPSI {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    fill: white;
    background-color: $BB_prime;
    border-radius: 50%;
    padding: 2px;
    &:hover {
        color: $BB_prime_hover; } }

.buttonPSI_dis {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    fill: white;
    background-color: #7a8086;
    border-radius: 50%;
    padding: 2px;
    cursor: default; }


.buttonPST {
    display: flex;
    justify-content: center;
    items-align: center;
    color: $BB_prime;
    height: 100%;
    &:hover {
        color: $BB_prime_hover; } }

.buttonPST_dis {
    display: flex;
    justify-content: center;
    items-align: center;
    color: #7a8086;
    height: 100%;
    cursor: default; }


.count {
    display: flex;
    justify-content: center;
    items-align: center;
    font-size: 12px;
    height: 100%; }

.icon1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $BB_prime;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: rgba(190,144,0,255); }
    &:disabled {
        background-color: #989899; } }

.icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $red;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: $red_hover; }
    &:disabled {
        background-color: #989899; } }


.icons {
    display: flex;
    align-items: center; }

.objednat_area {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.button_objednat {
    color: white;
    font-size: 18px;
    background-color: $BB_prime;
    padding: 8px;
    border-radius: 8px;
    &:hover {
        background-color: $BB_prime_hover; } }

.nadpis {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    height: 30px; }

.polozka_doklad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
    @include dark {
        color: white; } }

.icon_red {
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 4px;
    color: $red;
    display: flex;
    justify-content: center;
    align-items: center; }
