@import "../../../../styles/helpers";

.Card {
    display: flex;
    justify-content: center; }

.card {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 24px;
    background-color: #fcfcfc;
    border-radius: 8px;
    @include m {
        padding: 0px; }
    @include dark {
        background: $n7; } }

.searchHead {
    width: 100%; }


.buttonZmena {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    &:hover {
        background-color: rgba(190,144,0,255); }
    border-radius: 2px;
    @include t {
        font-size: 12px; } }


.header {
    width: 100%;
    display: flex;
    height: 36px;
    justify-content: space-between; }

.headerOverlay {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px; }

.row {
    width: 100%;
    display: flex;
    justify-content: space-between; }

.spustit {
    cursor: pointer;
    &:hover {
        color: $BB_prime; } }

.searchHead {
    width: 100%;
    margin-top: 10px; }

.title {
    font-size: 18px; }

.icons {
    color: $BB_prime;
    width: 50px;
    display: flex;
    justify-content: space-between; }

.icons2 {
    width: 100%;
    display: flex;
    justify-content: center; }

.settings {
    cursor: pointer;
    font-size: 20px;
    &:hover {
        color: rgba(190,144,0,255); } }

.play {
    cursor: pointer;
    font-size: 20px;
    color: $BB_prime;
    &:hover {
        color: rgba(190,144,0,255); } }

.star {
    cursor: pointer;
    color: $BB_prime;
    font-size: 20px;
    &:hover {
        color: rgba(190,144,0,255); } }
