@import "../../../../styles/helpers";

.head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .button {
        @include m {
            margin-left: 16px; }
        span {
            margin-left: 4px;
            @include m {
                display: none; } } } }

.info {
    max-width: 240px;
    margin-right: auto;
    font-weight: 500;
    color: $n4;
    strong {
        font-weight: 600;
        color: $n7;
        @include dark {
            color: $n1; } } }

.list {
    display: flex;
    overflow-x: scroll;
    // +d
    //     -webkit-overflow-scrolling: touch
    //     -ms-overflow-style: none
    //     scrollbar-width: none
    //     &::-webkit-scrollbar
 }    //         display: none

.list_without_scrollbar {
    display: flex;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none; }
    // +d
    //     -webkit-overflow-scrolling: touch
    //     -ms-overflow-style: none
    //     scrollbar-width: none
    //     &::-webkit-scrollbar
 }    //         display: none

.item,
.all {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 16px 8px;
    flex: 0 0 20%;
    text-align: center;
    color: $n7;
    transition: color .2s;
    @include s {
        flex: 0 0 50%;
        padding: 16px 8px; }
    @include dark {
        color: $n1; } }

.item {
    @include caption1-m; }

.all {
    @include caption1; }

.post {
    font-size: 11px;
    font-weight: 300; }

.cas {
    font-size: 12px;
    font-weight: 300;
    color: $shades1; }

.name {
    font-weight: 700; }

.avatar, {
    width: 64px;
    height: 64px;
    margin: 0 auto 12px;
    background-color: #f4f4f4;
    border-radius: 40%; }


.icon {
    width: 64px;
    height: 64px;
    margin: 0 auto 12px;
    padding-top: 20px;
    background-color: #f4f4f4;
    border-radius: 40%;
    display: flex;
    justify-content: center;
    alighn-items: center; }


.avatar {
    width: 60px;
    height: 60px;
    svg {
        fill: $shades1; } }


.all {
    &:hover {
        .icon {
            border-color: rgba(241, 182, 0, 1);
            background: #f4f4f4;
            svg {
                fill: rgba(241, 182, 0, 1); } } } }
