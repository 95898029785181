@import "../../styles/helpers";

.card {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 24px;
    background-color: #fcfcfc;
    border-radius: 8px;
    @include m {
        padding: 0px; }
    @include dark {
        background: $n7; } }

.pagination {
    margin-top: 10px; }

.hledejte {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.buttony {
    display: flex;
    flex-direction: row; }

.row1 {
    display: flex;
    width: 33%; }

.row2 {
    display: flex;
    width: 34%;
    justify-content: center; }

.row3 {
    display: flex;
    width: 33%;
    justify-content: end;
    gap: 10px; }


.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 48px;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 8px;
    @include t {
        font-size: 12px; } }

.search {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }

.placeholder {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }

.header {
    width: 100%;
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center; }

.icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $BB_prime;
    font-size: 20px;
    &:hover {
        color: $BB_prime_hover; } }

.icon2 {
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    color: $BB_prime;
    font-size: 15px;
    &:hover {
        color: $BB_prime_hover; } }
