@import "../../../styles/helpers";

.label {
    margin-bottom: 0px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.dropdown {
    position: relative;
    z-index: 3;
    &.active {
        z-index: 3;
        .head {
            box-shadow: inset 0 0 0 2px $BB_prime;
            background: $n;
            @include dark {
                box-shadow: inset 0 0 0 2px $BB_prime;
                background: $n8;
                color: $n1; }
            &:before {
                transform: rotate(180deg); } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.pridat {
    position: relative;
    z-index: 4;
    &.active {
        z-index: 4;
        .bodyPridat {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    position: relative;
    height: 48px;
    padding: 0 48px 0 12px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px $BB_prime;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    color: $n7;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 48px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto;
        transition: transform .2s; }
    &:hover {
        box-shadow: inset 0 0 0 2px $BB_prime; }
    @include dark {
        box-shadow: inset 0 0 0 2px $BB_prime;
        background: none;
        color: white;
        &:hover {
            box-shadow: inset 0 0 0 2px $BB_prime; } } }

.selection {
    cursor: pointer;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    background-color: transparent;
    color: $n4;
    @include dark {
        color: white; } }

.bodyPridat {
    height: 115px;
    width: 150px;
    top: 100px;
    left: -270px;
    overflow-y: scroll;
    position: absolute;
    margin-top: 12px;
    border: 8px solid white;
    border-radius: 12px;
    background: $n;
    box-shadow: 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1);
    visibility: hidden;
    @include dark {
        background: $n8;
        border: 8px solid $n8;
        box-shadow: 0 0 0 2px $BB_prime, 0 4px 12px rgba($n8, .1); }
    &.bodyUp {
        top: auto;
        bottom: calc(100% + 2px); } }


.body {
    height: 240px;
    width: 300px;
    top: 35px;
    overflow-y: scroll;
    position: absolute;
    right: 0px;
    margin-top: 12px;
    border: 8px solid white;
    border-radius: 12px;
    background: $n;
    box-shadow: 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1);
    visibility: hidden;
    @include dark {
        background: $n8;
        border: 8px solid $n8;
        box-shadow: 0 0 0 2px $BB_prime, 0 4px 12px rgba($n8, .1); }
    &.bodyUp {
        top: auto;
        bottom: calc(100% + 2px); } }

.option {
    display: flex;
    z-index: 0;
    padding: 4px 16px;
    color: $n4;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }

.icon {
    fill: $BB_prime;
    cursor: pointer; }

.text {
    margin-left: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between; }

.tools {
    display: flex;
    justify-content: space-between;
    cursor: pointer; }

.footer {
    cursor: pointer; }

.header_plus_tagy {
    height: 89%; }

.inputik {
    background-color: transparent;
    width: 100%;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    padding: 3px;
    @include dark {
        border: 1px solid white;
        color: white; } }


.colorSquar {
    height: 15px;
    width: 15px;
    margin: 3px;
    border: 1px solid white;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 5px; }

.red {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background-color: $red; }

.green {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background-color: green; }

.blue {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background-color: blue; }

.yellow {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background-color: yellow; }

.brown {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background-color: brown; }

.pink {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background-color: pink; }

.line {
    display: flex;
    flex-direction: row; }


.check {
    display: flex;
    justify-content: end;
    margin-top: 5px;
    cursor: pointer;
    color: $BB_prime; }

.upperLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.tagy {
    height: 85%;
    overflow-x: hidden; }

.icons_farba {
    display: flex;
    flex-direction: row; }

.trash {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    fill: black;
    @include dark {
        fill: white; } }

.pen {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    fill: black;
    @include dark {
        fill: white; } }

.textButton {
    font-size: 13px;
    &:hover {
        color: $BB_prime; } }
