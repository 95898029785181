@import "../../../../styles/helpers";

.row {
    display: table-row;
    transition: all .2s;
    &:hover {
        background: $n2;
        @include dark {
            background: $n6; } } }


.col {
    position: relative;
    display: table-cell;
    vertical-align: top; }

.col1 {

    flex-direction: row; }

.text {
    display: flex;
    justify-content: end;
    width: 30px; }


.item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .2s; }

.avatar {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.user {
    max-width: 160px;
    cursor: pointer; }

