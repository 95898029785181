@import "../../../styles/helpers";

.chart {
    width: auto;
    height: 274px;
    margin-left: -35px;
    margin-bottom: -10px; }

.nav {
    margin-bottom: 32px;
    padding: 8px;
    background: $n2;
    border-radius: 20px;
    @include m {
        margin-bottom: 24px; }
    @include dark {
        background: $shades4; } }


.ico {
    @include dark {
        color: black; } }

.item {
    display: flex;
    padding: 20px;
    flex: 0 0 50%;
    border-radius: 12px;
    background: $n1;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: all .2s;
    @include w {
        padding: 20px 16px; }
    @include m {
        display: block; }
    @include dark {
        background: $n6;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06); } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
    @include m {
        display: none; } }

.subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include caption1;
    color: $n4; }

.tooltip {
    svg {
        fill: $shades1; } }

.counter {
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.03em;
    @include xl {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600; }
    @include w {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600; }
    @include x {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600; }
    @include d {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600; }
    @include t {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600; }
    @include m {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600; }
    @include a {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600; }
    @include s {
        font-size: 16px;
        line-height: 30px;
        font-weight: 600; } }

.balance {
    margin: -12px -12px 0 auto;
    @include m {
        margin: 4px 0 0; }
    @include dark {
        background: $n6 !important; } }
