@import "../../../styles/helpers";

.outerZaznamProblemu {
    max-width: 700px;
    padding: 10px; }

.ico_Title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.button {
    display: flex;
    height: 36px; }

.buttonArea {
    display: flex;
    justify-content: end;
    margin-bottom: 10px; }

.polozka {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.panel {
    background-color: $red;
    margin-top: 10px; }

.checkbox input:checked ~ .checkmark {
    background-color: $red; }

.row {
    display: flex;
    justify-content: space-between; }

.star {
    color: $BB_prime;
    font-size: 20px; }

.title {
    display: flex;
    align-items: center;
    gap: 6px; }

.vzatvorke {
    font-size: 15px; }
