@import "../../../../styles/helpers";

.wrapper1 {
    height: 400px; }
.table {
    display: table; }

.outer {
    max-width: 1200px; }

.avatar {
    flex-shrink: 0;
    margin: 2px;
    display-flex {}
    align-items: center;
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include m {
        font-size: 9px; } }

.nazov {
    display: flex;
    flex-direction: row;
    padding: 4px; }

.toolbar {
    width: 100%;
    display: flex;
    justify-content: end; }
