@import "../../../styles/helpers";

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 8px;
    background: $n2;
    border-radius: 20px;
    @include m {
        margin-bottom: 24px; }
    @include dark {
        background: $shades4; } }

.item {
    display: flex;
    &.active {
        background: $n1;
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
        @include dark {
            background: $n6;
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.06); } } }


.avatar {
    position: relative;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    @include m {
        width: 48px;
        height: 48px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; }
    &.online {
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 20px;
            height: 20px;
            border: 4px solid $n;
            background: $p2;
            border-radius: 50%;
            transition: border-color .2s;
            @include dark {
                border-color: $n8; } } } }


.upperContentRight {
     margin-left: 15px; }


.items {
    display: flex;
    cursor: pointer;
    &:hover {
        background: $n2;
        border-radius: 8px;
        @include dark {
            background: $n6;
            border-radius: 8px; } } }

