@import "../../../styles/helpers";

.itemik {
    margin-bottom: 10px; }

.messages {
    position: relative;
    @include m {
        position: static; }
    &.active {
        .head {
            svg {
                fill: $n7;
                @include dark {
                    fill: $n1; } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }


.body {
    position: absolute;
    top: calc(100% + 18px);
    right: -12px;
    width: 300px;
    padding: 12px 24px 24px;
    border-radius: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    border: 1px solid transparent;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include x {
        top: calc(100% + 15px); }
    @include m {
        top: calc(100% - 1px);
        right: 16px;
        left: 16px;
        width: auto;
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; }
    @include hs {
        height: 300px;
        overflow-y: scroll;
        top: calc(100%); } }


.top {
    display: flex;
    align-items: center;
    margin: 0 -12px 12px 0;
    @include m {
        margin-right: -6px; } }

.title {
    margin-right: auto; }


.list {
    margin: 0 -12px;
    @include m {
        margin: 0 -8px; } }

.button {
    width: 100%;
    @include t {
        padding: 0 10px; } }


.item {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 12px;
    transition: background .2s;
    @include m {
        padding: 8px; }
    &:hover {
        background: $n3;
        @include dark {
            background: $n6; }
        @include m {
            background: none; } } }
.avatar {
    position: relative;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    @include m {
        width: 48px;
        height: 48px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex: 0 0 calc(100% - 56px);
    width: calc(100% - 56px);
    padding-left: 12px; }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding-right: 24px; }

.subtitle {
    margin-right: auto;
    font-weight: 700;
    color: $n7;
    @include dark {
        color: $n1; } }

