@import "../../../../styles/helpers";

.wrapper1 {}

.wrapper {
    margin: 0 0px;
    height: auto; }

.table {
    display: table;
    width: 100%; }

.avatars {
    width: 70px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }

.avatars2 {
    width: 35px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }

.avatar {
    display: flex;
    justify-content: center;
    width: 35px;
    img {
        width: 24px;
        height: 24px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; }
    div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        height: 24px;
        background-color: #6f767e;
        border-radius: 50%;
        object-fit: cover;
        &:hover {
            background-color: green; }
 } }        //font-family: 'Inter', sans-serif

.avatar_checkA {
    display: flex;
    justify-content: center;
    width: 35px;
    div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        height: 24px;
        background-color: green;
        border-radius: 50%;
        object-fit: cover;
 } }        //font-family: 'Inter', sans-serif

.avatar_checkP {
    display: flex;
    justify-content: center;
    width: 35px;
    div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        height: 24px;
        background-color: #6f767e;
        border-radius: 50%;
        object-fit: cover;
        &:hover {
            background-color: green; }
 } }        //font-family: 'Inter', sans-serif

.avatar_bezHover {
    display: flex;
    justify-content: center;
    width: 35px;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        height: 24px;
        background-color: #6f767e;
        border-radius: 50%;
        object-fit: cover; } }

.avatar_problemA {
    display: flex;
    justify-content: center;
    width: 35px;
    div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        height: 24px;
        background-color: $red;
        border-radius: 50%;
        object-fit: cover; } }

.avatar_problemP {
    display: flex;
    justify-content: center;
    width: 35px;
    div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        height: 24px;
        background-color: #6f767e;
        border-radius: 50%;
        object-fit: cover;
        &:hover {
            background-color: $red; }
 } }        //font-family: 'Inter', sans-serif

.avatar2 {
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include m {
        font-size: 9px; } }

.nazov {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2px; }

.groupe {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    @include m {
        font-size: 10px; } }

.icon_grp {
    height: 40px;
    display: flex;
    align-items: center;
    @include m {
        font-size: 10px; } }

.outer {
    max-width: 1200px; }

.title {
    font-size: 12px; }

.neexistujuData {
    height: 100%;
    display: flex;
    justify-content: center; }

.footer {
    width: 100%;
    text-align: end; }

.text_red {
    color: $red; }

.text_green {
    color: green; }

