@import "../../../styles/helpers";

.item {
    display: flex;
    justify-content: start;
    align-items: center; }


.cudo {
    height: 32px;
    width: 16px;
    border-radius: 4px;
    background-color: #ffdc8c; }

.title {
    display: flex;
    align-items: center;
    font-family: 'Inter' sans-serif;
    font-size: 20px;
    gap: 12px;
    margin-bottom: 5px;
    @include t {
       font-size: 15px; } }
