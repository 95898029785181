@import "../../../styles/helpers";

.wrapper1 {
    margin: -5px 0px;
    overflow-y : scroll; }

.wrapper {
    margin: 0 0px;
    height: auto; }

.table {
    display: table;
    width: 100%; }


.row {
    display: table-row; }

.underLine {
    display: table-row; }

.col {
    position: relative;
    display: table-cell;
    padding: 0 12px 16px;
    vertical-align: middle;
    @include caption1;
    color: $n4; }

.outer {
    max-width: 1200px; }
