@import "../../../styles/helpers";

.outerZaznamProblemu {
    //max-width: $max-width
 }    //padding: 10px

.content {
    //height: 100%
    width: 100%;
    overflow-y: auto; }

.ico_Title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.button {
    display: flex;
    height: 36px; }

.buttonArea {
    display: flex;
    justify-content: end;
    margin-bottom: 10px; }

.help {
    width: 100%; }

.polozka {
    width: 100%;
    min-width: 80px;
    display: flex;
    align-content: center;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px; }

.polozky_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    @include a {
        flex-direction: column; }
 }    //justify-content: space-between

.polozkavyjimka {
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    padding-right: 10px; }

.polozkavyjimka2 {
    width: 100%;
    display: flex;
    //align-content: center
    flex-direction: column;
    align-items: center; }


.panel {
    //box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    margin-top: 5px;
    border-radius: 8px;
    display: flex;
    flex-direction: column; }

.checkbox input:checked ~ .checkmark {
    background-color: $red; }

.select {
    width: 100%;
    height: 40px;
    border-radius: 8px; }

.label {
    height: 100%;
    display: flex;
    align-items: center; }

.link {
    border-radius: 4px;
    width: 150px;
    height: 30px;
    font-size: 15px;
    font-weight: 600;
    color: white;
    background-color: $BB_prime;
    &:hover {
        background-color: $BB_prime_hover; }

    &:disabled {
        color: #989899;
        border: 1px solid #dbd8d8; } }

.input {
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    width: 200px;
    height: 38px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 1px solid $BB_prime {
       background-color: white; }
    @include dark {
        background-color: #262b30; }
    &:disabled {
        color: #989899;
        border: 1px solid #dbd8d8; } }

.selector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    height: 38px;
    border: 1px solid #d0cccc;
    background-color: white;
    @include dark {
        background-color: #262b30; } }

.label {
    font-weight: 400;
    margin-left: 10px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #82878c; }

.title {
    height: 20px;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px; }

.sectionTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: $BB_prime; }

.sectionTitle_separator {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: $BB_prime;
    border-top: 2px solid $BB_prime;
    gap: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
       color: $BB_prime_hover; } }


.button {
    font-size: 16px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $BB_prime;
    &:hover {
        color: $BB_prime_hover; }
    &:disabled {
        color: #f1f1f1; } }

.inptLbl {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center; }

.saveArea {
    width: 100%;
    display: flex;
    justify-content: end; }

.save {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    &:hover {
        background-color: $BB_prime_hover; }
    @include t {
        font-size: 12px; } }


.checkboxLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #82878c;
    font-weight: 600; }

.checkbox {
    display: flex;
    justify-content: center;
    align-items: centerx;
    width: 18px;
    height: 18px; }

.separator_text {
    width: 100%;
    display: flex;
    justify-content: center; }

.title_polozky_row {
    display: flex;
    flex-direction: column; }


.title_polozky_row_title {
    font-size: 15px;
    color: $BB_prime; }

.input_textArea {
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 200px;
    height: 60px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 1px solid $BB_prime {
       background-color: white; }
    @include dark {
        background-color: #262b30; }
    &:disabled {
        color: #989899;
        border: 1px solid #dbd8d8; } }

.dropzone {
    background-color: #f4f4f4;
    @include dark {
         background-color: #262b30;
         color: white; } }
