@import "../../../styles/helpers";

.wrapper1 {
    margin: -5px 0px;
    overflow-y : scroll; }



.wrapper {
    margin: 0 0px; }

.table {
    display: table;
    width: 100%; }

.row {
    display: table-row; }

.col {
    position: relative;
    display: table-cell;
    @include caption1;
    color: $n4;
    width: 20%;
    font-size: 11px; }

.col1 {
    position: relative;
    display: table-cell;
    @include caption1;
    color: $n4;
    width: 40%;
    font-size: 11px; }

.outer {
    max-width: 1200px; }

.buttonPS {
    display: flex;
    margin-top: 10px; }

.buttonPSI {
    fill: white;
    background-color: #f0b500;
    border-radius: 50%;
    padding: 4px;
    margin-bottom : 20px;
    margin-right: 20px;
    @include t {
       padding: 3px; } }

.buttonPST {
    display: flex;
    text-align: center;
    justify-content :center {}
    items-align: center;
    color: #f0b500;
    font-weight: bold;
    font-size: 20px;
    padding-top: 6px;
    @include t {
       padding-top: 7px;
       font-size: 13px; } }

.pridatSurovinu_spolu {
    display: flex;
    justify-content: space-between;
    padding-right: 3%; }

.spolu {
    display: flex;
    text-align: center;
    justify-content :center {}
    items-align: center;
    font-weight: bold;
    font-size: 20px;
    padding-top: 12px;
    @include t {
       padding-top: 15px;
       font-size: 13px; } }

.expand {
    width: 100%; }
