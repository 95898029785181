@import "../../../../styles/helpers";

.wrapper1 {}

.wrapper {
    margin: 0 0px; }

.spacebeetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px; }

.start {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 30px; }

.avatar {
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.avatar2 {
    img {
        width: 24px;
        height: 24px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px; }

.nazov {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 4px;
    font-size: 10px; }

.outer {
    max-width: 1200px; }

.buttonPS {
    min-width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 15px;
    cursor: pointer; }

.buttonPSI {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    fill: white;
    background-color: $BB_prime;
    border-radius: 50%;
    padding: 2px;
    &:hover {
        color: $BB_prime_hover; } }

.buttonPSI_dis {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    fill: white;
    background-color: #7a8086;
    border-radius: 50%;
    padding: 2px;
    cursor: default; }


.buttonPST {
    display: flex;
    justify-content: center;
    items-align: center;
    color: $BB_prime;
    height: 100%;
    &:hover {
        color: $BB_prime_hover; } }

.buttonPST_dis {
    display: flex;
    justify-content: center;
    items-align: center;
    color: #7a8086;
    height: 100%;
    cursor: default; }


.count {
    display: flex;
    justify-content: center;
    items-align: center;
    font-size: 12px;
    height: 100%; }

.icon1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $BB_prime;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: rgba(190,144,0,255); }
    &:disabled {
        background-color: #989899; } }

.icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $red;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: $red_hover; }
    &:disabled {
        background-color: #989899; } }


.icons {
    display: flex;
    align-items: center; }

.objednat_area {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    overflow: auto; }

.objednat_area_2 {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.objednat_area_3 {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 15px;
    overflow: auto;
    border-bottom: 2px solid #cdcdcd; }


.textarea_area {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.button_objednat {
    color: white;
    font-size: 18px;
    background-color: $BB_prime;
    padding: 8px;
    border-radius: 8px;
    &:hover {
        background-color: $BB_prime_hover; } }

.nadpis_vytvoril {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

.nadpis {
    font-size: 18px;
    @include mat {
        font-size: 14px; }
    @include s {
        font-size: 11px; } }
.vytvoril {
    font-size: 13px;
    color: #666d74;
    @include s {
        font-size: 10px; } }


.polozka_doklad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
    @include dark {
        color: white; } }

.icon_pdf {
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 4px;
    color: $red;
    display: flex;
    justify-content: center;
    align-items: center; }

.polozka_vyb {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
    gap: 4px; }

.polozka_res {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: orange;
    gap: 4px; }

.polozka_neuz {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $red;
    gap: 4px; }

.polozka_neutral {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    gap: 4px; }

.icon_bb_prime {
    width: 20px;
    height: 20px;
    background-color: $BB_prime;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_green {
    width: 20px;
    height: 20px;
    background-color: $Croco_prime;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_orange {
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_red {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: $red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_gray {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color:#7a8086 {}
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_neutral {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color:#7a8086 {}
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.header {
    height: 40px;
    width: 100%;
    font-size: 14px;
    font-weight: 500; }

.header_cont {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center; }

.back {
    display: flex;
    justify-content: center;
    align-items: center; }

.f_line {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.s_line {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.cell_center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.koment {
    height: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }

.textarea {
    width: 100%;
    height: 30px;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid #cdcdcd;
    background-color: white;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; } }


.fotky {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    overflow: auto; }

.main {
    overflow: auto; }

.dokl {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap; }
