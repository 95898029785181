@import "../../../styles/helpers";

.header {
    display: flex;
    justify-content: space-around; }

.leftArrow {
    display: flex;
    justify-content: start; }

.rightArrow {
    display: flex;
    justify-content: end; }

.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f4f4f4;
    fill: #989da3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include dark {
        background-color: #262b30; }
    &:hover {
        background-color: #eee;
        @include dark {
            background-color: #101415; } } }

.text_mesiac {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; }

.divider {
    display: flex;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    height: 30px;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #efefef;
    @include dark {
        border-top: 1px solid #262b30; } }

.day {
    color: #9a9fa5;
    width: 100%;
    display: flex;
    justify-content: center; }

.mesiac {
    display: flex;
    flex-direction: column;
    margin-top: 10px; }

.tyzden {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }

.den {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 95px;
    width: 100%;
    &:hover {
        background-color: #f4f4f4;
        @include dark {
            background-color: #262b30; } }
    @include mat {
        height: 145px; } }

.pic {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mat {
        width: 15px;
        height: 15px; } }

.pic_around {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mat {
        width: 100%; } }

.day_number {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 40px;
    height: 40px;
    @include mat {
        font-size: 15px;
        width: 20px;
        height: 20px; } }

.day_number_aktualny {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 40px;
    height: 40px;
    color: white;
    background-color: $BB_prime;
    border-radius: 50%;
    @include mat {
        font-size: 12px;
        width: 20px;
        height: 20px; } }

.day_number_disable {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 40px;
    height: 40px;
    color: #c3c4c4;
    @include dark {
        color: #545c5c; }
    @include mat {
        font-size: 15px;
        width: 20px;
        height: 20px; } }

.shop {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    color: #c4c7ca;
    @include mat {
        flex-direction: column; } }

.cycle {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    color: #c4c7ca;
    @include mat {
        flex-direction: column; } }

.moznost {
    position: relative;
    z-index: 4;
    &.active {
        z-index: 4;
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.body {
    height: 100px;
    width: 160px;
    top: -120px;
    left: -150px;
    position: absolute;
    border-radius: 16px;
    background: $n;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include dark {
        background: #101415; } }


.item {
    display: flex;
    height: 40px;
    width: 85%;
    justify-content: start;
    align-items: center;
    padding-left: 5px;
    font-size: 13px;
    cursor: pointer;
    &:hover {
        border-radius: 8px;
        background-color: #f4f4f4;
        @include dark {
            background-color: #1a1d1e; } }
    @include mat {
        font-size: 11; } }

.ico {
    margin-right: 4px;
    @include dark {
        fill: white; } }

.button_zmena_area {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: end;
    align-items: center; }

.button_zmena {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $BB_prime;
    color: white;
    height: 40px;
    width: 90px;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
    &:hover {
        background-color: rgba(190,144,0,255); } }
