@import "../../styles/helpers";

.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    margin: 2px;
    &:hover {
        background-color: $red_hover; } }

.iconDis {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7a8086;
    fill: white;
    border-radius: 4px;
    margin: 2px; }
