@import "../../../../styles/helpers";

.item {
    display: flex;
    padding: 20px;
    flex: 0 0 50%;
    border-radius: 12px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: all .2s;
    @include w {
        padding: 20px 16px; }
    @include m {
        display: block; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
    @include m {
        display: none; } }

.subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include caption1;
    color: $n4; }

.tooltip {
    svg {
        fill: $shades1; } }

.counter {
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.03em;
    @include xl {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600; }
    @include w {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600; }
    @include x {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600; }
    @include d {
        font-size: 14px;
        line-height: 30px;
        font-weight: 600; }
    @include t {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600; }
    @include m {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600; }
    @include a {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600; }
    @include s {
        font-size: 16px;
        line-height: 30px;
        font-weight: 600; } }

.balance1 {
    margin: -12px -12px -0 auto;
    @include m {
        margin: 4px 0 0; } }

.ico {
    color: #9a9fa5 !important; }

.balance2 {
    margin: -12px -12px 0 auto;
    @include m {
        margin: 4px 0 0; } }
