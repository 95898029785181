@import "../../styles/helpers";

.nadpis {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    height: 30px; }

.polozka {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 10px; }

.polozka_doklad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
    @include dark {
        color: white; } }

.polozka_reklamace {
    gap: 5px;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: $BB_prime; } }

.polozka_vyb {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: green;
    gap: 4px; }

.polozka_res {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: orange;
    gap: 4px; }

.icon_green {
    width: 20px;
    height: 20px;
    background-color: $Croco_prime;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_orange {
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_red {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    color: $red;
    display: flex;
    justify-content: center;
    align-items: center; }

.nazov {
    width: 100%;
    display: flex;
    justify-content: end; }

.znacka {
    width: 100%;
    display: flex;
    justify-content: end;
    color: #a6a6a6;
    font-size: 11px; }

.obrazok {
     & > img {
        height: 45px;
        width: 45px; } }

.content {
    position: relative;
    display: inline-block; }

.overlay_text {
    width: 40px;
    position: absolute;
    top: 80%;
    left: 23px;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 10px;
    font-weight: bold;
    background-color: $BB_prime; }

.reklamace {
    font-size: 16px;
    height: 35px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $Croco_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: $Croco_prime_hover; } }
