@import "../../styles/helpers";


.searchHead {
    width: 100%; }

.count {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 3%;
    @include m {
        font-size: 12px; } }

.headModal {
    display: flex;
    justify-content: space-between; }

.textarea2 {
    height: 80px;
    margin-right: 30px;
    border-radius: 4px;
    background-color: #f4f4f4;
    @include dark {
        background-color: #272b30;
        color: white; }
    @include a {
       height: 40px;
       width: 100%; } }


.buttonZmena {
    color: #FFF;
    font-weight: bold;
    padding: 3px;
    background-color: $BB_prime;
    border-radius: 2px;
    margin-right: 5px;
    @include t {
        font-size: 12px; } }

.upperContentRight {
    margin-left: 15px;
    @include t {
        font-size: 12px; } }

.upperContent2 {
    height: 70px;
    display: flex;
    align-items: center; }


.Content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include a {
        flex-direction: column; } }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    margin: 10px;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButton {
    margin-right: 20px; }

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    @include m {
        width: 30px;
        height: 30px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }
