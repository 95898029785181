@import "../../styles/helpers";

// .head
//     margin-bottom: 44px
//     +t
//         margin-bottom: 32px
//     +m
//         flex-wrap: wrap
//         margin-bottom: 24px
//     .form
//         margin-right: auto
//         +t
//             width: 250px
//         +m
//             width: 100%
//             margin: 0 0 16px
//     &.hidden
//         +t
//             display: none

.title {
    margin-right: 24px;
    @include m {
        margin: 0 0 16px; } }

.nav {
    display: flex;
    @include m {
        width: 100%; } }


.filters {
    margin-left: 8px;
    @include t {
        display: none; } }


.row {
    width: 100%;
    &.flex {
        display: flex;
        .table {
            flex-shrink: 0; } } }

.headerTable {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 50px;
    width: 120px; }

.headerTableObjDay {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 50px;
    width: 120px;
    background-color: #ffe9cd;
    // border-right: 2px solid #f0cc71
 }    // border-left: 2px solid #f0cc71

.headerFilterN {
    color: #a8a8a8;
    cursor: pointer;
    &:hover {
        color: $BB_prime; } }

.headerFilterA {
    color: $BB_prime;
    cursor: pointer; }

.headerTable_datum {
    height: 50%;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12.5px; }

.headerTable_datum_today {
    height: 50%;
    display: flex;
    align-items: center;
    color: $BB_prime;
    gap: 5px;
    font-size: 12.5px; }

.cell {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50px;
    width: 70px;
    background-color: #ffe9cd;
    @include dark {
       background-color: #d5c0a5; } }

.cell_dis {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50px;
    width: 70px; }


.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    display-flex {}
    align-items: center;
    img {
        width: 28px;
        height: 28px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    font-size: 13px; }

.S_O {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 100%;
    font-size: 10px; }

.nazov {
    width: 300px;
    display: flex;
    flex-direction: row;
    padding: 4px; }

.nazov_2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px;
    width: 340px;
    gap: 2px; }

.hotovo {
    background-color: #a3d094;
    color: white;
    padding: 2px;
    border-radius: 4px;
    font-size: 11px; }

.vyrizuje_se {
    background-color: #929898;
    color: white;
    padding: 2px;
    border-radius: 4px;
    font-size: 11px; }

.hotovo_special {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background-color: #a3d094;
    color: white;
    padding: 2px;
    border-radius: 4px; }


.nova {
    background-color: #ffa404;
    color: white;
    // +dark
    //     background-color: rgb(249, 241, 220)
    //     color: #ffa404
    padding: 2px;
    border-radius: 4px;
    font-size: 11px; }

.text {
    display: flex;
    width: 70px;
    background-color: transparent;
    color: #969b9f;
    @include dark {
        color: white; } }


.textred {
    height: 50px;
    width: 70px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    color: white;
    background-color: $red; }

.textgreen {
    height: 50px;
    width: 70px;
    padding-right: 6px;
    color: white;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    background-color: green; }

.textyellow {
    height: 50px;
    width: 70px;
    padding-right: 6px;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    color: black;
    background-color: yellow; }

.textorange {
    height: 50px;
    width: 70px;
    padding-right: 6px;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    color: white;
    background-color: orange; }

.mobile {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.pokladna {
    width: 100%;
    fill: $BB_prime;
    display: flex;
    justify-content: end;
    align-items: center; }

.nazovTitle {
    margin-left: 6px; }

.cardHead {
    display: flex;
    flex-direction: row; }

.dd {
    width: 100%;
    display: flex;
    justify-Content: end;
    flex-direction: row; }

.ddtitle {
   padding-right: 8px; }

.dditem {
   display: flex;
   align-items: center;
   padding-left: 8px; }

.dropdown {
    width: 240px;
    @include t {
        width: 140px; } }

.zoomout {
    @include s {
        zoom: 75%; } }

.addButton {
    color: white;
    background-color: #f0cc71;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #f0cc71;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: 5px; }

.znacka {
    display: flex;
    flex-direction: row; }

.znackaText {
    display: flex;
    flex-direction: row;
    width: 100px; }

.tagy {
    display: flex;
    flex-direction: row; }

$primary-color: #4d4d4d;

@mixin my-element($color) {
  color: $color; }

.search_kosik {
    display: flex;
    align-items: center;
    gap: 10px; }

.kosik_ico {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 28px;
    height: 28px;
    background-color: $BB_prime;
    &:hover {
        background-color: $BB_prime_hover; }
    border-radius: 4px;
    cursor: pointer; }

.kosik_ico_dis {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 28px;
    height: 28px;
    background-color: $disabled;
    border-radius: 4px; }

.search {
    width: 350px;
    @include t {
        width: 260px; }
    @include m {
        width: 230px; } }

.LoadingOverlay {
    z-index: 9999;
    position: absolute; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }


input[type=number] {
  -moz-appearance:textfield {} }
