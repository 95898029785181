@import "../../../styles/helpers";

.outerZaznamProblemu {
    max-width: 400px;
    padding: 10px; }

.ico_Title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.button {
    display: flex;
    height: 36px; }

.buttonArea {
    display: flex;
    justify-content: end;
    margin-bottom: 10px; }

