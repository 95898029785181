@import "../../styles/helpers";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($n2,.8);
  opacity: 0;
  animation: showModal .4s forwards;
  z-index: 999;
  @include m {
    padding: 0px; }
  @include dark {
    background: rgba($n6, .9); } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  max-height: 1000px;
  min-height: 500px;
  margin: auto;
  // margin-top: 15%
  // margin-bottom: 15%
  background: $n1;
  border-radius: 16px;
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @include dark {
    background: $n7; }
  @include m {
    box-shadow: none; }
  // & > div
 }  //   padding: 16px 10px 0px

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $n3;
  font-size: 0;
  svg {
    fill: $n5;
    transition: transform .2s; }
  @include dark {
    background: $n6;
    svg {
      fill: $n1; } }
  &:hover {
    svg {
      transform: rotate(90deg); } } }
