@import "../../../styles/helpers";

.outerZaznamProblemu {
    //max-width: $max-width
 }    //padding: 10px


.ico_Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.button {
    display: flex;
    height: 36px; }

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px; }

.buttonArea {
    display: flex;
    justify-content: end;
    margin-bottom: 10px; }

.help {
    width: 100%; }

.input {
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;
    width: 200px;
    height: 38px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 1px solid $BB_prime {
       background-color: white; }
    @include dark {
        background-color: #262b30; }
    &:disabled {
        color: #989899;
        border: 1px solid #dbd8d8; } }

.selector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    height: 38px;
    border: 1px solid #d0cccc;
    background-color: white;
    @include dark {
        background-color: #262b30; } }

.label {
    font-weight: 400;
    margin-left: 10px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #82878c; }

.title {
    height: 20px;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px; }


.button {
    font-size: 16px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $BB_prime;
    &:hover {
        color: $BB_prime_hover; }
    &:disabled {
        color: #f1f1f1; } }

.inptLbl {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center; }

.saveArea {
    width: 100%;
    display: flex;
    justify-content: space-between; }

.save {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    @include m {
        width: 180px; }
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    &:hover {
        background-color: $BB_prime_hover; } }

.tre_pok {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    gap: 5px; }

.checkboxLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #82878c;
    font-weight: 600; }

.checkbox {
    display: flex;
    justify-content: center;
    align-items: centerx;
    width: 18px;
    height: 18px; }

.separator_text {
    width: 100%;
    display: flex;
    justify-content: center; }

.title_polozky_row {
    display: flex;
    flex-direction: column; }


.title_polozky_row_title {
    font-size: 15px;
    color: $BB_prime; }

.avatar {
    display: flex;
    justify-content: center;
    width: 35px;
    img {
        width: 24px;
        height: 24px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; }
    div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 24px;
        height: 24px;
        background-color: #6f767e;
        border-radius: 50%;
        object-fit: cover;
        &:hover {
            background-color: green; }
 } }        //font-family: 'Inter', sans-serif


.avatar2 {
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px; }

.nazov {
    display: flex;
    flex-direction: row;
    padding: 4px;
    font-size: 10px;
    gap: 10px; }

.title {
    font-size: 12px; }

.head {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.LoadingOverlay {
    // position: fixed
    // z-index: 1000
    opacity: 1; }

.content {
    //height: 100%
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column; }

.img_zna_naz {
    height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; }

.inputy {
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: start; }

.input {
    width: 100%;
    display: flex;
    text-align: end;
 }    //align-items: end

.buttonUlozit {
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center; }

.inp_mj {
    display: flex;
    align-items: center;
    gap: 10px; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }


input[type=number] {
  -moz-appearance:textfield {} }
