@import "../../../styles/helpers";

.info {
    margin-bottom: 24px;
    font-weight: 500;
    color: $n4; }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include a {
        display: block;
        margin: 0; } }

.item {
    display: flex;
    align-items: center;
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 32px 16px 0;
    color: $n7;
    cursor: pointer;
    @include a {
        width: 100%;
        margin: 0; }

    @include dark {
        color: $n1; }
    &:hover {
        .icon {
            background: $n3;
            @include dark {
                background: $n6; } } }
    &:not(:last-child) {
        @include a {
            margin-bottom: 24px; } } }

.poz1 {
    flex: 1;
    margin-bottom: 10px; }

.img1 {
    width: 46px;
    height: 46px; }

.img2 {
    width: 68px;
    height: 46px; }

.img3 {
    width: 46px;
    height: 26px; }

.img4 {
    width: 48px;
    height: 48px; }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 13px;
    transition: background .2s;
    svg {
        fill: $n6; }
    @include dark {
        svg {
            fill: $n1; } } }

.title {
    font-size: 14px;
    flex: 10;
    text-align: start;
    margin-top: 22px; }


.line {
    display: flex;
    width: 190px; }


.status {
    margin-right: 4px;
    color: $n7; }

.user {
    display: flex;
    align-items: center;
    padding: 1px 8px 1px 1px;
    border: 1px solid $n3;
    border-radius: 4px;
    @include dark {
        border-color: $n6; } }

.avatar {
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    margin-right: 4px;
    font-size: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2px; } }

.action {
    @include caption2;
    color: $n4; }
