@import "../../../styles/helpers";

.outerZaznamProblemu {
 }    //max-width: 400px

.ico_Title {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.button {
    display: flex;
    height: 36px; }

.buttonArea {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px; }


.tag {
    display: flex;
    margin-left: 20px;
    margin-bottom: 5px; }

.text {
    margin-left: 5px; }

.tagy {
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px; }

.row {
    display: flex;
    justify-content: space-between; }
