@import "../../styles/helpers";

.page {
    padding: 96px 0 0 340px;
    @include xxl {
        padding-left: 96px; }
    @include t {
        overflow: hidden; }
    @include m {
        padding-left: 0; } }

.sidebar {
    @include m {
        transform: translateX(-100%);
        transition: transform .3s;
        &.visible {
            transform: translateX(0); } } }
.inner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 96px);
    padding: 40px;
    @include xxl {
        padding: 24px 24px; }
    @include m {
        padding: 24px 16px; } }

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 80%;
    margin: 0 auto;
    @include x {
        width: 100%; }
    &.wide {
        max-width: 100%; } }

.title {
    margin-bottom: 24px;
    @include m {
        margin-bottom: 16px; } }

.dropdown {
    width: 150px;
    margin-left: 5px;
    @include m {
        margin-bottom: 16px; }
    @include a {
        width: 100px; } }

.datepicker {
    width: 150px;
    height: 40px;
    position: relative;
    padding: 0 0px 0 12px;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px $BB_prime;
    font-size: 14px;
    font-weight: 800;
    line-height: 48px;
    color: $n7;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
    transition: all .2s;
    background-color: transparent;
    @include dark {
        box-shadow: inset 0 0 0 2px $BB_prime;
        background: none;
        color: white;
        &:hover {
            box-shadow: inset 0 0 0 2px $BB_prime; } }
    @include a {
        width: 100px; } }

.calendar {
    background-color: white;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px $n3;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    @include s {
        padding: 0px; }
    @include dark {
        box-shadow: inset 0 0 0 2px $BB_prime;
        background: #1e2730;
        color: $n4; } }


.box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center; }


.pageTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
