@import "../../styles/helpers";

.dropzone {
    height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px; }


.dropzone_under {
    height: 85px;
    padding-left: 5px;
    padding-right: 5px; }

.drop_zone_aroud {
    height: 100px;
    width: 100%;
    border: 2px dashed #ACACAC;
    border-radius: 10px;
    @include m {
        width: 150px; } }

.drop_zone_aroud_req {
    height: 100px;
    width: 100%;
    border: 2px dashed $BB_prime;
    border-radius: 10px;
    @include m {
        width: 150px; } }

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }

.files {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    overflow: auto; }

.IconFile {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }

.IconFileText {
    display: flex;
    justify-content: center;
    align-items: center; }

.IconClose {
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #cc0000; } }

.file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.file_around {
    position: relative;
    width: 60px;
    height: 65px;
    padding: 2px; }

