@import "../../../styles/helpers";

.outer {
    position: absolute;
    padding: 16px;
    top: 55px;
    right: 110px;
    background-color: white;
    z-index: 999;
    border-radius: 8px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1); }


.Card {
    display: flex;
    justify-content: center; }

.card {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 24px;
    background-color: #fcfcfc;
    border-radius: 8px;
    @include m {
        padding: 0px; }
    @include dark {
        background: $n7; } }

.searchHead {
    width: 100%; }


.buttonZmena {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    &:hover {
        background-color: rgba(190,144,0,255); }
    border-radius: 2px;
    @include t {
        font-size: 12px; } }


.header {
    width: 100%;
    display: flex;
    height: 36px;
    justify-content: space-between; }

.headerOverlay {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px; }

.row {
    width: 100%;
    display: flex;
    justify-content: space-between; }

.row_menu {
    padding-left: 8%;
    padding-right: 8%;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #6F767E;
    &:hover {
        background-color: #efefef;
        border-radius: 8px;
        color: #404245;
        cursor: pointer; } }

.row_menu2 {
    padding-left: 11%;
    padding-right: 8%;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #6F767E;
    &:hover {
        background-color: #efefef;
        border-radius: 8px;
        color: #404245;
        cursor: pointer; } }

.buletPoint {
    height: 100%;
    width: 12px;
    border-radius: 4px;
    color: #adacac;
    display: flex;
    align-items: center; }


.row_menu_exp {
    padding-left: 8%;
    padding-right: 8%;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6F767E;
    &:hover {
        background-color: #efefef;
        border-radius: 8px;
        color: #404245;
        cursor: pointer; } }

.f {
    display: flex;
    flex-direction: column; }

.divider {
    height: 1px;
    width: 100%;
    background-color: #efefef; }

.row_menu_divider {
    padding-left: 5%;
    padding-right: 5%;
    height: 12px;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center; }


.spustit {
    cursor: pointer;
    &:hover {
        color: $BB_prime; } }

.searchHead {
    width: 100%;
    margin-top: 10px; }

.title {
    font-size: 18px; }

.icons {
    color: $BB_prime;
    width: 50px;
    display: flex;
    justify-content: space-between; }

.icons2 {
    width: 100%;
    display: flex;
    justify-content: center; }

.settings {
    cursor: pointer;
    font-size: 20px;
    &:hover {
        color: rgba(190,144,0,255); } }

.play {
    cursor: pointer;
    font-size: 20px;
    color: $BB_prime;
    &:hover {
        color: rgba(190,144,0,255); } }

.star {
    cursor: pointer;
    color: $BB_prime;
    font-size: 20px;
    &:hover {
        color: rgba(190,144,0,255); } }
