@import "../../../styles/helpers";

.outerZaznamProblemu {
    padding: 10px; }

.ico_Title {
    display: flex;
    justify-content: end;
    font-size: 20px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.button {
    display: flex;
    height: 36px; }

.buttonArea {
    display: flex;
    justify-content: end;
    margin-bottom: 10px; }

.buttonZmena {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100px;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 2px;
    &:hover {
        background-color: rgba(190,144,0,255); }
    @include t {
        font-size: 12px; } }

.ZobrazHtml {
    overflow: scroll;
    padding-top: 5px;
    border-top: 1px solid black;
    @include dark {
        border-top: 1px solid white; } }

.nadpis {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid rgba(#9A9FA5, .25); }

.rekl {
    color: $BB_prime;
    cursor: pointer;
    &:hover {
        color: $BB_prime_hover; } }

.rekl_line {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: start; }
