@import "../../../styles/helpers";

.ico_Title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.ico {
    display: flex;
    justify-content: center;
    align-items: end;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.pic {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; }

.pic_around {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 20px; }

.outer {
    max-width: 1100px;
    padding: 15px;
    @include a {
        padding: 5px; } }


.icon {
    fill: #9A9FA5;
    @include dark {
        fill: #f4f4f4; } }

.dateArea {
    display: flex;
    flex-direction: row;
    margin-top: 5px; }

.dateAreaLine {
    display: flex;
    justify-content: space-between; }

.date {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 48px;
    background: #F4F4F4;
    border-radius: 12px;
    color: $BB_prime;
    border: 0px solid $BB_prime;
    padding: 12px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    &:hover {
        border: 1px solid $BB_prime;
        color: black;
        @include dark {
            color: white; } } }

.od {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 30px;
    height: 48px;
    color: #9A9FA5; }

.do {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 30px;
    height: 48px;
    color: #9A9FA5;
    margin-left: 5px; }

.timeLine {
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    margin-left: 12px;
    height: 29px;
    border: 0px solid $BB_prime;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        border: 0px solid $BB_prime; }
    @include a {
        gap: 6px;
        margin-left: 6px; } }

.time {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    color: $BB_prime;
    width: 40px;
    &:hover {
        color: black;
        @include dark {
            color: white; } }
    @include a {
        font-size: 11px;
        width: 25px; } }

.punctuation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    color: $BB_prime;
    width: 10px;
    &:hover {
        color: black;
        @include dark {
            color: white; } }
    @include a {
        font-size: 12px; } }

.text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: rgba(111, 118, 126, 0.4); }

.Switch {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }

.buttonArea {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center; }

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $BB_prime;
    color: white;
    height: 40px;
    width: 90px;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
    &:hover {
        background-color: rgba(190,144,0,255); } }

.calendar {
    background-color: white;
    border-radius: 12px;
    box-shadow: inset 0 0 0 2px $n3;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    @include s {
        padding: 0px; }
    @include dark {
        box-shadow: inset 0 0 0 2px $BB_prime;
        background: #1e2730;
        color: $n4; } }

.setTime {
    position: relative;
    z-index: 4;
    &.active {
        z-index: 4;
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.body {
    height: 200px;
    width: 140px;
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 16px;
    background: $n;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    @include dark {
        background: #101415; } }

.timePicker {
    display: flex;
    width: 100%;
    justify-content: space-around; }

.hour {
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    height: 29px;
    width: 60px;
    color: $BB_prime;
    border: 0px solid $BB_prime;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        border: 1px solid $BB_prime;
        color: black;
        @include dark {
            color: white; } } }

.minute {
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    height: 29px;
    width: 60px;
    color: $BB_prime;
    border: 0px solid $BB_prime;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        border: 1px solid $BB_prime;
        color: black;
        @include dark {
            color: white; } } }

.hours {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    height: 100px;
    margin-top: 5px; }

.h {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 25px;
    cursor: pointer;
    &:hover {
        color: $BB_prime; } }

.minutes {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    height: 100px;
    margin-top: 5px; }

.m {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    cursor: pointer;
    &:hover {
        color: $BB_prime; } }

.buttonTime {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $BB_prime;
    color: white;
    height: 20px;
    width: 60px;
    border-radius: 4px;
    font-size: 15px;
    cursor: pointer;
    margin-right: 5px;
    z-index: 999;
    &:hover {
        background-color: rgba(190,144,0,255); } }

.overlay {
    display: flex;
    flex-direction: row; }

.colorText {
    color: $BB_prime; }


.ico2 {
    width: 90%;
    display: flex;
    justify-content: end;
    align-items: start;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }
