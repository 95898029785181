@import "../../styles/helpers";

.row {
    display: flex;
    @include d {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 360px);
        width: calc(100% - 360px);
        padding-right: 8px;
        @include d {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 360px;
        @include x {
            width: 360px; }
        @include d {
            width: 100%; } } }

.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.title {
    display: flex;
    margin-bottom: 24px;
    @include m {
        margin-bottom: 16px; } }

.test {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-left: 10px; }
