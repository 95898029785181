@import "../../styles/helpers";

.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BB_prime;
    cursor: pointer;
    color: white;
    border-radius: 4px;
    margin: 2px;
    &:hover {
        background-color: $BB_prime_hover; } }

.modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50px;
    width: 150px;
    height: 39px;
    background-color: #f2f2f2;
    border-radius: 8px;
    border: 2px solid rgba(#9A9FA5, .25); }
.volba {
    width: 50px;
    display: flex;
    justify-content: center; }
