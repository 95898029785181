@import "../../styles/helpers";

.center {
    @include m {
        height: 40px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center; } }

.itemik {
    margin-bottom: 10px; }

.messages {
    position: relative;
    @include m {
        position: static; }
    &.active {
        .head {
            svg {
                fill: $n7;
                @include dark {
                    fill: $n1; } } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.head {
    position: relative;
    width: 48px;
    height: 48px;
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n7;
            @include dark {
                fill: $n1; } } }
    &.active {
        &:before {
            content: "";
            position: absolute;
            top: 10px;
            right: 10px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid $n;
            background: $p3;
            @include dark {
                border-color: $n7; } } } }

.body {
    position: absolute;
    top: calc(100% + 18px);
    right: -12px;
    width: 300px;
    border-radius: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    border: 1px solid transparent;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include x {
        top: calc(100% + 15px); }
    @include m {
        top: calc(100% - 1px);
        right: 16px;
        left: 16px;
        width: auto;
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; } }

.top {
    display: flex;
    align-items: center;
    margin: 0 -12px 12px 0;
    @include m {
        margin-right: -6px; } }

.title {
    margin-right: auto;
    @include title1-s; }

.actions {
    .actionsHead {
        background: $n3;
        svg {
            fill: $n7; }
        @include dark {
            background: $n6;
            svg {
                fill: $n1; } } } }

.list {
    margin: 0 -12px;
    @include m {
        margin: 0 -8px; } }

.button {
    width: 100%;
    @include t {
        padding: 0 10px; } }

.outer {
    max-width: 1200px;
    height: 900px;
    @include hd {
        widhth: 100%; } }


.outerZaznamProblemu {
    max-width: 600px; }

.count {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 20px;
    @include m {
        font-size: 12px; } }


.searchHead {
    width: 100%; }

.itemZmenaDodavatele {
    display: flex;
    margin-left: 3%;
    margin-right: 1%;
    margin-bottom: 5px;
    padding: 3px;
    cursor: pointer;
    &:hover {
        background: $n3;
        border-radius: 12px;
        @include dark {
            background-color: #272b30; } } }

.upperContent {
    height: 70px;
    display: flex;
    align-items: center; }

.upperContent2 {
    height: 70px;
    display: flex;
    align-items: center; }

.upperContent3 {
    height: 70px;
    display: flex;
    align-items: center; }

.upperContentComment {
    display: flex;
    @include t {
        font-size: 12px; } }

.upperContentRow {
    height: 70px;
    display: flex;
    align-items: center;
    justify-Content: space-between; }

.upperContentRowPrevod {
    height: 120px;
    display: flex;
    align-items: center;
    justify-Content: space-between; }

.upperContentSecondLine {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include t {
        padding-top: 15px; } }


.Content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include a {
        flex-direction: column; } }

.upperContentScr {
    max-height: 400px; }

.upperContentLeft {
    margin-right: 20px; }

.upperContentRight {

    margin-left: 15px;
    @include t {
        font-size: 12px; } }

.upperContentRightA {
    color: $BB_prime;
    margin-left: 15px;
    @include t {
        font-size: 12px; } }

.upperContentRightP {
    margin-left: 15px;
    @include t {
        font-size: 12px; } }

.headModal {
    display: flex;
    justify-content: space-between; }

.headModalENP {
    display: flex;
    justify-content: space-between; }

.headModalTlacidla {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; }

.headModalTlacidlaZ {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; }

.headModalButtonsActive {
    color: $BB_prime;
    font-weight: bold;
    border-bottom: 1.5px solid $BB_prime;
    margin: 7px;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    margin: 10px;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }


.buttonZmena {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-weight: bold;
    padding: 3px;
    background-color: $BB_prime;
    border-radius: 2px;
    margin-right: 5px;
    fill: white;
    &:hover {
        background-color: rgba(190,144,0,255); }
    @include t {
        font-size: 12px; } }

.inputDodaciList {
    color: #000;
    font-weight: 700;
    padding: 6px;
    background-color: #EFEFEF;
    border-radius: 2px;
    margin-left: 20px;
    width: 100px; }

.inputNakup {
    color: #000;
    width: 25%;
    min-width: 50px;
    padding: 6px;
    font-size: 18px;
    background-color: #EFEFEF;
    border-radius: 2px;
    margin-left: 20px; }

.upperContentDodaciList {
    margin-left: 15px;
    @include t {
        font-size: 12px; } }

.item {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 12px;
    transition: background .2s;
    @include m {
        padding: 8px; }
    &:hover {
        background: $n3;
        @include dark {
            background: $n6; }
        @include m {
            background: none; }
        .avatar.online:before {
            border-color: $n3;
            @include m {
                border-color: $n; }
            @include dark {
                border-color: $n6;
                @include m {
                    border-color: $n8; } } } } }

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    @include m {
        width: 30px;
        height: 30px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex: 0 0 calc(100% - 56px);
    width: calc(100% - 56px);
    padding-left: 12px; }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding-right: 24px; }

.subtitle {
    margin-right: auto;
    font-weight: 700;
    color: $n7;
    @include dark {
        color: $n1; } }

.btns {
    margin-top: 24px;
    text-align: right; }

.ico_Title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.ico {
    display: flex;
    justify-content: end;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }


.start {
    display: flex; }

.textarea {
    width: 100%;
    height: 90px;
    resize: false;
    border-radius: 4px;
    background-color: #f4f4f4;
    @include dark {
        background-color: #272b30;
        color: white; } }

.area {
    display: flex;
    justify-content: center;
    item-align: center;
    width: 300px;
    flex-direction: column;
    @include m {
        width: 120px; } }

.contentEXDOD {
    display: flex;
    justify-content: space-between;
    flex-direction:  row;
    @include a {
        flex-direction:  column; } }

.buttonPotvrdeniePrevod {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    margin-right: 3%; }
