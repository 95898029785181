@import "../../styles/helpers";

.page {
    padding: 74px 0 0 340px;
    @include xxl {
        padding-left: 96px; }
    @include t {
        overflow: hidden; }
    @include m {
        padding-left: 0; } }

.sidebar {
    @include m {
        transform: translateX(-100%);
        transition: transform .3s;
        &.visible {
            transform: translateX(0); } } }

.inner {
    display: flex;
    flex-direction: column;
    //min-height: calc(100vh - 96px)
    padding: 24px;
    @include m {
        padding: 0px; } }

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    &.wide {
        max-width: 100%; } }

.title {
    margin-bottom: 24px;
    @include m {
        margin-bottom: 16px; } }

.notfound {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.button {
    padding: 4px;
    border-radius: 4px;
    background-color: $BB_prime;
    cursor: pointer;
    color: white;
    &:hover {
        background-color: $BB_prime_hover; } }
