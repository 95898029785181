@import "../../styles/helpers";

.negative,
.positive {
    display: inline-flex;
    align-items: center;
    margin-right: 2px;
    @include caption2;
    svg {
        margin-right: 4px; } }

.negative {
    color: $p3;
    svg {
        fill: $p3; } }

.positive {
    color: $p2;
    svg {
        fill: $p2; } }

.background {
    &.positive,
    &.negative {
        height: 24px;
        padding: 0 4px;
        border-radius: 8px; }
    &.positive {
        background: #EAFAE5;
        @include dark {
            background: rgba(#EAFAE5, .25); } }
    &.negative {
        background: #FFE7E4;
        @include dark {
            background: rgba(#FFD8D3, .15); } } }
