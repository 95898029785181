@import "../styles/helpers.sass";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 48px;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 8px;
    &:hover {
        background-color: $BB_prime_hover; } }
