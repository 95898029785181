@import "./../../styles/helpers";

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #23a638;
    color: white;
    height: 100px;
    margin: 20px;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 10px 10px 30px -8px rgba(0, 0, 0, 0.3), 8px 32px 48px -8px rgba(0, 0, 0, 0.15);
    &:hover {
        cursor: pointer;
        background-color: #19d437; } }

.overlay {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.title {
    width: 50%;
    height: 100px;
    display: flex;
    justify-content: start;
    align-items: center; }

.search {
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: end;
    align-items: center; }

.x_ {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: end;
    align-items: center; }

.x {
    cursor: pointer; }

.title_x {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center; }

.input {
    width: 320px;
    height: 48px;
    padding: 0 68px 0 42px;
    background: $n2;
    border: 2px solid transparent;
    border-radius: 12px;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        background: $n6;
        color: $n1; }
    &::placeholder {
        color: $shades1; }
    &:focus {
        border-color:  rgba(241, 182, 0, 1);
        background: $n;
        @include dark {
            background: $n8; } } }
