@import "../../styles/helpers";

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px;
    background: $n1;
    @include m {
        padding: 24px; }
    @include dark {
        background: $n7; } }

.wrapper {
    max-width: 350px;
    @include m {
        max-width: 100%; } }

.logo {
    display: inline-block;
    width: 72px;
    margin-bottom: 32px;
    img {
        width: 100%; } }

.title {
    margin-bottom: 32px; }

.head {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.subtitle {
    margin-bottom: 20px;
    @include body2-s; }

.btns {
    display: flex;
    margin: 0 -4px;

    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px; } } }
.body {
    .button {
        width: 100%; } }

.field1 {
    margin-bottom: 12px; }

.silaHesla {
    display: flex;
    justify-content: center;
    gap: 8px; }

.button_sign_in {
    width: 100%;
    border-radius: 12px;
    background-color: $BB_prime;
    color: white;
    height: 48px;
    font-size: 15px;
    &:hover {
        background-color: $BB_prime_hover; }
    &:disabled {
        cursor: default;
        background-color: #7a8086; } }


.field2 {
    margin-bottom: 12px; }


.note {
    margin-top: 32px;
    @include body2-s;
    color: $shades1; }


.badLogin {
    width: 100%;
    display: flex;
    justify-content: center;
    color: $red;
    font-weight: 700;
    margin-bottom: 10px; }


.info {
    margin-top: 32px;
    @include caption1;
    color: $n4;
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $n1; }
        &:hover {
            color: $p1; } } }

.loader {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }

.butonynaprihlasenie {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between; }

.button_sign_in_help {
    width: 48%;
    border-radius: 12px; }
