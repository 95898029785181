@import "../../styles/helpers";

.searchHead {
    width: 100%; }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    margin: 10px;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110%;
    height: 100%;
    fill: white;
    gap: 2px;
    padding: 8px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 8px;
    @include t {
        font-size: 12px; } }

.headModal {
    display: flex;
    justify-content: space-between; }
