@import "../../styles/helpers";

.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BB_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    margin: 2px;
    &:hover {
        background-color: $BB_prime_hover; } }
