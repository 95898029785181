@import "../../../styles/helpers";

.overlay {
    display: flex;
    justify-content: center; }

.searchHead {
    width: 100%; }


.buttonPotvrdeniePrevod {
    display: flex;
    align-items: center; }

.title {
    display: flex;
    align-items: center;
    font-size: 18px;
    @include t {
        font-size: 15px; } }


.header {
    display: flex;
    justify-content: space-between; }

.count {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 12px; }

.headModal {
    display: flex;
    justify-content: space-between; }

.textarea2_title {
    width: 100%;
    font-size: 12px; }

.textarea2 {
    height: 80px;
    width: 100%;
    border-radius: 4px;
    background-color: #f4f4f4;
    @include dark {
        background-color: #272b30;
        color: white; }
    @include a {
       height: 40px;
       width: 100%; } }


.buttonZmena {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    &:hover {
        background-color: rgba(190,144,0,255); }
    border-radius: 2px;
    @include t {
        font-size: 12px; } }

.upperContent {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    font-size: 12px;
    @include m {
        margin-bottom: 5px; } }

.Content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include a {
        flex-direction: column; } }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }


.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    @include m {
        width: 30px;
        height: 30px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.cieloveStredisko {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }

.cieloveStrediskoButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; }

.cieloveStrediskoContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 5px; }

.header {
    width: 100%;
    display: flex;
    justify-content: space-between; }

.row {
    width: 100%;
    display: flex;
    justify-content: space-between; }

.spustit {
    cursor: pointer;
    &:hover {
        color: rgba(190,144,0,255); } }

.searchHead {
    width: 100%;
    margin-top: 10px; }
