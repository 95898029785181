@import "../../../styles/helpers";

.item {
    display: flex; }

.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex-grow: 1; }


.tooltip {
    margin-left: 10px;
    svg {
        fill: $shades1; } }

.line {
    display: flex;
    align-items: center; }

.title {
    font-weight: 700;
    @include w {
        font-size: 14px; }
    @include x {
        font-size: 14px; }
    @include d {
        font-size: 14px; }
    @include t {
        font-size: 20px; }
    @include m {
        font-size: 20px; }
    @include a {
        font-size: 20px; }
    @include s {
        font-size: 14px; } }


.subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    @include caption1;
    color: $n4; }

.login {
    font-weight: 500;
    color: $shades1; }

.time {
    margin-left: 20px;
    @include caption1;
    color: $shades1; }

.content {
    margin-bottom: 8px;
    font-weight: 500;
    color: $shades1;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } } }

.comment1 {
    flex: 1;
    justify-content: space-between;
    font-weight: 500;
    color: $n7;
    @include dark {
        color: $n1; } }


.ico {
    @include dark {
        color: $n1; } }

.comment2 {
    flex: 4;
    text-align: center;
    font-weight: 500;
    color: $n7;
    @include dark {
        color: $n1; } }
.comment3 {
    flex: 2;
    text-align: end;
    font-weight: 500;
    color: $n7;
    @include dark {
        color: $n1; } }


.control {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.link {
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.button {
    width: 100%; }
