@import "../../../styles/helpers";
.searchHead {
    width: 100%; }

.count {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 20px;
    @include m {
        font-size: 12px; } }

.headModal {
    display: flex;
    justify-content: space-between; }

.upperContentLeft {
    margin-right: 20px; }

.upperContent2 {
    height: 70px;
    display: flex;
    align-items: center; }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    margin: 10px;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButton {
    margin-right: 20px; }
