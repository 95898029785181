@import "../../../../styles/helpers";

.negative,
.positive {
    display: inline-flex;
    flex-Direction: column;
    align-items: center;
    margin-right: 1px;
    @include caption2;
    @include m {
        flex-Direction: row; }
    @include s {
        flex-Direction: column; }
    svg {
        margin-right: 4px; } }



.positive {
    color: black;
    svg {
        fill: black; }
    @include dark {
        color: white;
        svg {
            fill: white; } } }

.avg {
    padding: 5%;
    margin: 5%;
    border-radius: 8px;
    height: 24px;
    width: 80px;
    background: #efefef;
    @include dark {
        background: #585f5d; } }
