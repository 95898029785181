@import "../../../styles/helpers";

.item {
    display: flex;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n6; } }

.avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 12px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        background-color: #ffbc99;
        padding: 4px; } }

.details {
    flex-grow: 1; }

.line {
    display: flex;
    align-items: center; }

.user {
    margin-right: auto; }

.title {
    font-weight: 700; }

.poz1 {
    margin-left: 20px;
    @include caption1;
    color: green;
    padding: 2px;
    background: #EAFAE5; }

.poz2 {
    margin-left: 20px;
    @include caption1;
    padding: 2px;
    color: $red;
    background: #FFE7E4; }

.poz3 {
    margin-left: 20px;
    @include caption1;
    padding: 2px;
    color: orange;
    background: #FFD88D; }

.content {
    margin-bottom: 8px;
    font-weight: 500;
    color: $shades1;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } } }

.comment {
    font-weight: 500;
    color: $n7;
    @include dark {
        color: $n1; } }

.control {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.link {
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.button {
    width: 100%; }
