@import "../../../styles/helpers";

.item {
    display: flex;
    justify-content: space-between;
    height: 55px; }

.logo {
    width: 24px; }

.logo_around {
    background-color: #f4f4f4;
    border-radius: 50%;
    padding: 6px;
    margin: 1px;
    @include dark {
        background-color: #262b30; } }


.firstPart {
    display: flex;
    align-items: center; }

.secondPart {
    display: flex;
    align-items: center;
    font-size: 12px; }

.ok {
    color: #83bf6e;
    background-color: #eafae5;
    padding: 5px;
    border-radius: 8px; }


.mid {
    color: #e7604d; }

.bad {
    color: #bfc2c5;
    background-color: #f4f4f4;
    padding: 5px;
    border-radius: 8px;
    @include dark {
        background-color: #262b30; } }

.divider {
    display: flex;
    justify-content: space-between;
    color: #6f757e;
    padding: 10px;
    border-bottom: 1px solid #efefef;
    @include dark {
        border-bottom: 1px solid #262b30; } }

.vsechny_zmeny {
    cursor: pointer;
    padding: 10px;
    border: 2px solid #efefef;
    text-align: center;
    margin-top: 10px;
    @include dark {
        border: 2px solid #262b30; }
    &:hover {
        background-color: #f4f4f4;
        @include dark {
            background-color: #101415; } } }

.zmena {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 4px;
    font-size: 13px;
    cursor: pointer;
    border-bottom: 1px solid #f4f4f4;
    @include dark {
        border-bottom: 1px solid #262b30; }
    &:hover {
        fill: $BB_prime;
        background-color: #f4f4f4;
        @include dark {
            background-color: #101415; } } }

.icon1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-left: 3px;
    margin-right: 3px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $BB_prime;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: rgba(190,144,0,255); } }

.icon2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-left: 3px;
    margin-right: 3px;
    padding: 1px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $red;
    fill: white;
    @include dark {
        fill: white; }
    &:hover {
        background-color: $red_hover; } }

.icon {
    margin-right: 4px;
    padding: 1px;
    @include dark {
        fill: white; } }

.text {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center; }

.icons {
    display: flex;
    align-items: center; }
