@import "../../styles/helpers";
.searchHead {
    width: 100%; }

.count {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 20px;
    @include m {
        font-size: 12px; } }

.headModal {
    display: flex;
    justify-content: space-between; }

.upperContentLeft {
    margin-right: 20px; }

.upperContent2 {
    height: 70px;
    display: flex;
    align-items: center; }

.headModalButtons {
    color: #ACACAC;
    font-weight: bold;
    margin: 10px;
    fill: $BB_prime;
    @include a {
        font-size: 10px; }
    @include s {
        font-size: 9px; } }

.headModalButton {
    margin-right: 20px; }

.icon {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BB_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 6px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: $BB_prime_hover; } }

.iconDis {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7a8086;
    fill: white;
    border-radius: 4px;
    padding: 6px;
    color: white; }

.addNew {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start; }


.icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around; }


.iconY {
    font-size: 18px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BB_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: $BB_prime_hover; } }

.iconR {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: $red_hover; } }

.nadpis {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    height: 30px; }

.polozka {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px; }

.znacka {
    color: #a6a6a6;
    font-size: 11px; }

.obrazok {
     & > img {
        height: 45px;
        width: 45px; } }

.content {
    position: relative;
    display: inline-block; }

.overlay_text {
    width: 40px;
    position: absolute;
    top: 80%;
    left: 23px;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 10px;
    font-weight: bold;
    background-color: $Croco_prime_hover; }

.reklamace {
    font-size: 14px;
    height: 35px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $Croco_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: $Croco_prime_hover; }
    &:disabled {
        background-color: $disabled;
        cursor: default; } }

.reklamace_exist {
    font-size: 14px;
    height: 35px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e06818;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: #90410c; }
    &:disabled {
        background-color: $disabled;
        cursor: default; } }
