@import "../../../../styles/helpers";

.negative,
.positive {
    display: inline-flex;
    flex-Direction: column;
    align-items: center;
    margin-right: 1px;
    @include caption2;
    @include m {
        flex-Direction: column; }
    @include s {
        flex-Direction: column; }
    svg {
        margin-right: 4px; } }

.avgP {
    padding: 5%;
    margin: 5%;
    border-radius: 8px;
    height: 24px;
    width: 90px;
    color: $p2;
    background: #efefef;
    @include dark {
        background: #585f5d; } }



.avgN {
    padding: 5%;
    margin: 5%;
    border-radius: 8px;
    height: 24px;
    width: 90px;
    color: $p3;
    background: #efefef;
    @include dark {
        background: #585f5d; } }
