@import "../../styles/helpers";

.head {
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px; }
    .form {
        margin-right: auto;
        @include t {
            width: 250px; }
        @include m {
            width: 100%;
            margin: 0 0 16px; } }
    &.hidden {
        @include t {
            display: none; } } }

.title {
    margin-right: 24px;
    @include m {
        margin: 0 0 16px; } }

.nav {
    display: flex;
    @include m {
        width: 100%; } }


.filters {
    margin-left: 8px;
    @include t {
        display: none; } }


.row {
    width: 100%;
    &.flex {
        display: flex;
        .table {
            flex-shrink: 0; } } }

.headerTable {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100px;
    width: 120px;
    padding: 2px; }

.headerTable_trzba {
    fill: $BB_prime;
    height: 20%; }

.headerTable_datum {
    height: 20%; }

.cell {
    height: 50px;
    width: 70px;
    background-color: transparent; }


.avatar {
    flex-shrink: 0;
    margin: 2px;
    display-flex {}
    align-items: center;
    img {
        width: 36px;
        height: 36px;
        background-color: #b5e4ca;
        border-radius: 50%;
        object-fit: cover; } }

.nazov_kod {
    display: flex;
    flex-direction: column; }

.S_O {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: end; }

.nazov {
    width: 300px;
    display: flex;
    flex-direction: row;
    padding: 4px; }

.hotovo {
    background-color: #83bf6e;
    color: white;
    padding: 2px;
    border-radius: 4px; }

.nova {
    background-color: #fff3cd;
    color: #f6cb48;
    padding: 2px;
    border-radius: 4px; }

.text {
    height: 25px;
    width: 70px;
    padding-right: 6px;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    border-top: 0.1px solid #edebeb;
    background-color: transparent;
    color: #969b9f;
    @include dark {
        color: white; } }


.textred {
    height: 25px;
    width: 70px;
    padding-right: 6px;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    color: white;
    background-color: $red; }

.textgreen {
    height: 25px;
    width: 70px;
    padding-right: 6px;
    color: white;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    background-color: green; }

.textyellow {
    height: 25px;
    width: 70px;
    padding-right: 6px;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    color: black;
    background-color: yellow; }

.textorange {
    height: 25px;
    width: 70px;
    padding-right: 6px;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: end;
    color: white;
    background-color: orange; }

.mobile {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.pokladna {
    width: 100%;
    fill: $BB_prime;
    display: flex;
    justify-content: end;
    align-items: center; }

.nazovTitle {
    margin-left: 6px; }

.cardHead {
    display: flex;
    flex-direction: row; }

.dd {
    width: 100%;
    display: flex;
    justify-Content: end;
    flex-direction: row; }

.ddtitle {
   padding-right: 8px; }

.dditem {
   display: flex;
   align-items: center;
   padding-left: 8px; }

.dropdown {
    width: 240px;
    @include t {
        width: 140px; } }

.input {
  text-align: right; }

.zoomout {
    @include s {
        zoom: 75%; } }

.addButton {
    color: white;
    background-color: #f0cc71;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #f0cc71;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-left: 5px; }

.znacka {
    display: flex;
    flex-direction: row;
    width: 238px; }

.znackaText {
    display: flex;
    flex-direction: row;
    width: 100px; }

.tagy {
    display: flex;
    flex-direction: row; }

$primary-color: #4d4d4d;

@mixin my-element($color) {
  color: $color; }

