@import "../../../styles/helpers";

.nadpis_dokoncit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px; }

.nadpis {
    font-size: 24px;
    height: 30px; }

.dokoncit {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $BB_prime;
    font-size: 12px;
    height: 30px;
    width: 85px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: $BB_prime_hover; } }

.main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    @include mat {
        overflow: auto;
        flex-direction: column; } }

.left {
    height: 100%;
    min-height: 338px;
    min-width: 308px;
    width: 33%;
    //background-color: $red
    display: flex;
    flex-direction: column;
    padding: 12px;
    padding-right: 10px;
    border-right: 2px solid #cdcdcd;
    @include mat {
        border-right: 0px solid #cdcdcd;
        width: 100%; } }

.right {
    height: 100%;
    width: 67%;
    padding: 8px;
    min-height: 338px;
    @include mat {
        width: 100%; } }


.polozka {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: cent;
    gap: 15px; }

.doklad {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: cent;
    gap: 15px; }

.koment {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start; }

.fotky {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: start; }

.nazov_s {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid #cdcdcd;
    background-color: white;
    cursor: pointer;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; }
    &:required {
        border: 2px solid $BB_prime; } }


.nazov {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid #cdcdcd;
    background-color: white;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; }
    &:required {
        border: 2px solid $BB_prime; } }

.nazov_req {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid $BB_prime;
    background-color: white;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; } }

.nazov_s_req {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid $BB_prime;
    background-color: white;
    cursor: pointer;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; } }

.nazov_input {
    width: 100%;
    display: flex;
    text-align: center;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid #cdcdcd;
    background-color: white;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; } }

.nazov_input_req {
    width: 100%;
    display: flex;
    text-align: center;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid $BB_prime;
    background-color: white;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; } }



.select_area {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    color: #82878c; }

.textarea {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid #cdcdcd;
    background-color: white;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; } }

.textarea_req {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 15px;
    font-weight: 600;
    color: #82878c;
    border: 2px solid $BB_prime;
    background-color: white;
    @include dark {
        background-color: #262b30;
        color: #8f8f8f; } }


.input_nadpis {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }

.input_nadpis_1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }

.input_nadpis_2 {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
 }    //align-items: start

.input_nadpis_3 {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }

.input_nadpis_4 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }

.input_nadpis_5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }

.input_nadpis_6 {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }

.nazov_text_1 {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
    border-right: 1px solid #cdcdcd;
    padding-left: 4px;
    width: 120px; }

.nazov_text_2 {
    display: flex;
    justify-content: start;
    align-items: center;
    width: calc(100% - 140px);
    padding-left: 4px; }

.nazov_lupa {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    padding-right: 4px;
    &:hover {
        cursor: pointer;
        color: $BB_prime; } }

.zoznam_mnozstiev {
    height: calc(100% - 55px);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: auto; }

.pridat_do_zoznamu {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end; }

.pridat {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $BB_prime;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        background-color: $BB_prime_hover; } }

.doklad_layout {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    gap: 15px; }

.doklad_layout_2 {
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    gap: 15px; }

.doklad_add {
    height: 280px;
    width: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    padding: 8px;
    //border-bottom: 2px solid #cdcdcd
    background-color: #f4f4f4;
    border-radius: 8px; }

.input_icon {
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $red;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #E21111; } }

.close {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center; }

.btn {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        color: $BB_prime_hover; } }

