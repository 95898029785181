@import "../../../styles/helpers";

.nadpis {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 18px;
    height: 30px; }

.polozka {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 10px; }

.polozka_center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }

.polozka_doklad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px;
    @include dark {
        color: white; } }

.polozka_reklamace {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 4px; }

.polozka_vyb {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: green;
    gap: 4px; }

.polozka_res {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: orange;
    gap: 4px; }

.polozka_neuz {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: $red;
    gap: 4px; }

.polozka_neutral {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    color: black;
    gap: 4px; }


.icon_eye {
    width: 30px;
    height: 30px;
    background-color: $BB_prime;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: $BB_prime_hover; } }

.icon_trash_a {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: $red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: $red_hover; } }

.icon_trash_d {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: $disabled;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_bb_prime {
    width: 20px;
    height: 20px;
    background-color: $BB_prime;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_green {
    width: 20px;
    height: 20px;
    background-color: $Croco_prime;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_orange {
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_red {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: $red;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_gray {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color:#7a8086 {}
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.icon_neutral {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color:#7a8086 {}
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }

.actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.nazov {
    width: 100%;
    display: flex;
    justify-content: end; }

.znacka {
    width: 100%;
    display: flex;
    justify-content: end;
    color: #a6a6a6;
    font-size: 11px; }

.obrazok {
     & > img {
        height: 45px;
        width: 45px; } }

.content {
    position: relative;
    display: inline-block; }

.overlay_text {
    width: 40px;
    position: absolute;
    top: 80%;
    left: 23px;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 10px;
    font-weight: bold;
    background-color: $Croco_prime_hover; }

.reklamace {
    font-size: 16px;
    height: 35px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $Croco_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    gap: 4px;
    &:hover {
        background-color: $Croco_prime_hover; } }

.iconsis {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }

.iconY {
    font-size: 18px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BB_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 4px;
    color: white;
    &:hover {
        background-color: $BB_prime_hover; } }
