@import "../../../../styles/helpers";

.wrapper1 {
    margin: -5px 0px;
    overflow-y : scroll; }


.wrapper {
    margin: 0 0px;
    height: auto; }

.table {
    display: table;
    width: 100%; }


.row {
    display: table-row; }

.underLine {
    display: table-row; }

.col {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    color: $n4;
    width: 25%;
    font-size: 12px; }

.col1 {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    color: $n4;
    margin: 10px;
    width: 30%;
    font-size: 12px; }


.outer {
    max-width: 1200px; }
