@import "../../../styles/helpers";

.outerZaznamProblemu {
    padding: 10px; }

.ico_Title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    fill: #33383f;
    @include dark {
        fill: #f4f4f4; } }

.button {
    display: flex;
    height: 36px; }

.buttonArea {
    display: flex;
    justify-content: end;
    margin-bottom: 10px; }

.buttonZmena {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100px;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 8px;
    &:hover {
        background-color: rgba(190,144,0,255); }
    @include t {
        font-size: 12px; } }

.ZobrazHtml {
    //height: calc( 100% -95px )
    overflow: auto;
    padding-top: 5px;
    border-top: 1px solid black;
    @include dark {
        border-top: 1px solid white; } }

.ZobrazHtmlText {
    height: 35px;
    display: flex;
    justify-content: start;
    align-items: center; }

.ZobrazHtml_title {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 18px;
    @include m {
        font-size: 12px; } }

.buttonCancel {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 200px;
    height: 100%;
    fill: white;
    gap: 4px;
    padding: 4px;
    color: #FFF;
    font-weight: bold;
    background-color: $BB_prime;
    border-radius: 8px;
    &:hover {
        background-color: rgba(190,144,0,255); }
    @include t {
        font-size: 12px; } }
