@import "../../styles/helpers";

.icon {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BB_prime;
    cursor: pointer;
    fill: white;
    border-radius: 4px;
    padding: 6px;
    color: white;
    &:hover {
        background-color: $BB_prime_hover; } }

.iconDis {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7a8086;
    fill: white;
    border-radius: 4px;
    padding: 6px;
    color: white; }

.addNew {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start; }
