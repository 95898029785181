@import "../../../styles/helpers";

.list {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
    position: relative;
    overflow-x: scroll; }

.list_without_scrollbar {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
    position: relative;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none; } }



.item {
    color: $n7;
    @include dark {
        color: white;
        svg {
            fill: white; } }
    &:not(:first-child) {
        border-width: 0 0 0 1px;
        border-style: solid;
        border-color: #f4f4f4;
        @include dark {
            border-color: #111315; } } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-top: 2px;
    svg {
        fill: $n7; } }

.boxpicked {
    display: flex;
    padding: 4px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px 2px 2px #FFF, inset 0px -1px 1px rgba(0, 0, 0, 0.1);
    @include dark {
        background-color: #272b30;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), inset 0px 2px 2px #34373c, inset 0px -1px 1px rgba(0, 0, 0, 0.1); } }
.box {
    display: flex;
    cursor: pointer; }

.itempicked {
    color: $BB_prime;
    @include dark;
    padding-left: 3px;
    padding-right: 3px {
        color: $n1; } }

.iconpicked {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-top: 2px;
    svg {
        fill: $BB_prime; } }

.counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    font-weight: 400;
    line-height: 1;
    font-size: 12px;
    padding-left: 4px;
    padding-right: 4px; }
